<template>
  <div class="pa-2">
    <v-dialog v-model="atualizaDados" persistent width="20vw">
      <v-card>
        <v-card-title>Atualizando dados...</v-card-title>
        <v-card-text class="d-flex justify-center align-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-carousel
      cycle
      height="93vh"
      interval="20000"
      @change="zeraProgress"
      hide-delimiter-background
      show-arrows-on-hover
      v-model="model"
      hide-delimiters
    >
      <v-carousel-item v-for="(os, i) in banners" :key="i">
        <v-card height="88vh" color="white">
          <div
            class="d-flex align-center justify-center white--text text-h4"
            style="background: black; height: 50px"
          >
            Fila atendimento de {{ os.tecnico }} até
            {{ `${dia}/${mes}/${ano}` }} -
            <span class="text-h6 ml-3">{{ os.pag }}</span>
            <!-- {{
              `Fila Atendimento de ${os.tecnico} até ${dia}/${mes}/${ano} -`
            }} -->
          </div>
          <v-card-text>
            <div v-for="(itens, i) in os.pagina" :key="i">
              <div
                v-if="itens.DIAS_ATENDIMENTO"
                class="d-flex justify-space-between align-center mt-2 pa-1"
              >
                <v-icon
                  color="red darken-2"
                  v-if="itens.DIAS_ATENDIMENTO > 0"
                  size="42"
                  class="mr-4"
                >
                  mdi-ear-hearing-loop
                </v-icon>
                <v-icon color="green darken-2" v-else size="42" class="mr-4">
                  mdi-ear-hearing-loop
                </v-icon>
                <div class="d-flex flex-column align-start">
                  <div class="black--text text--darken-3 text-h4">
                    {{ `${itens.ZF1_ID} - ${itens.A1_NOME}` }}
                  </div>
                  <div class="grey--text text-h6">
                    {{ `${itens.ZF1_NOMFIL} - ${itens.ZF1_EMISSA}` }}
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex justify-center align-center">
                  <div
                    v-if="
                      itens.ZB3_STATUS === '000005' ||
                        itens.ZB3_STATUS === '000018'
                    "
                  >
                    <v-chip
                      class="ma-2"
                      :color="
                        itens.STATUS_OS === 'FORA DO PRAZO'
                          ? 'red darken-2'
                          : 'green darken-2'
                      "
                      text-color="white"
                    >
                      {{ itens.STATUS_OS }}
                    </v-chip>
                  </div>
                  <div v-else>
                    <v-chip
                      class="ma-2"
                      :color="
                        itens.STATUS_OS1 === 'FORA DO PRAZO'
                          ? 'red darken-2'
                          : 'green darken-2'
                      "
                      text-color="white"
                    >
                      {{ itens.STATUS_OS1 }}
                    </v-chip>
                  </div>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-chip
                    class="ma-2"
                    color="grey darken-2"
                    text-color="white"
                    v-if="itens.ZB3_STATUS !== '000018'"
                  >
                    {{ itens.ZB3_STATUS }} - {{ itens.ZB2_DESCRI }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="green darken-2"
                    text-color="white"
                    v-else
                  >
                    {{ itens.ZB3_STATUS }} - {{ itens.ZB2_DESCRI }}
                  </v-chip>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- <v-footer dark padless> -->
        <!-- <div class="d-flex justify-end align-end white--text py-2 px-2" style="background: black; border-radius: 5px;"> 
              {{ os.tecnico }} — <strong> {{ os.pag }}</strong>
            </div> -->
        <div class="mt-1">
          <v-progress-linear
            v-model="valueBar"
            :color="valueBar <= 90 ? 'green darken-2' : 'red darken-3'"
            height="07"
          ></v-progress-linear>
        </div>
        <div class="d-flex justify-center align-end">
          <span class="black--text caption">{{
            `Próxima atualização: ${timer}`
          }}</span>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FilaAtendimento",
  data: () => ({
    model: 0,
    controlOs: [],
    dia: null,
    mes: null,
    ano: null,
    paginas: [],
    banners: [],
    valueBar: 0,
    intervalPag: null,
    intervalAtualiza: null,
    atualizaDados: false,
    timer: null,
    timerContSet: null,
    cont: 0,
    min: 3,
    seg: 0,
    nrFilaAtendimento: 0,
  }),

  methods: {
    async buscaOS() {
      await this.buscaNrAtendimento();

      clearInterval(this.intervalPag);
      clearInterval(this.timerContSet);

      this.min = 3;
      this.seg = 0;
      this.atualizaDados = true;
      this.controlOs = [];

      const dataAtual = new Date();
      this.ano = dataAtual.getFullYear();
      this.mes = dataAtual.getMonth() + 1;
      this.dia = dataAtual.getDate();

      if (this.mes.toString().length <= 1) {
        this.mes = `0${this.mes}`;
      }

      if (this.dia.toString().length <= 1) {
        this.dia = `0${this.dia}`;
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "ZZK_COD,ZZK_NOME",
        WHERE: `ZZK_AT='S'`,
        TABELA: "ZZK",
      };

      const tecnicos = await axios.post(url, dados);

      this.paginas = [];
      await Promise.all(
        tecnicos.data.map(async item => {
          dados = {
            FIELDS: `ZF1_MSFIL,ZF1_NOMFIL,ZF1_CODCLI,ZF1_LOJA,ZF1_ID,ZF1_EMISSA,A1_NOME,ZF1_PESO,ZB2_DESCRI,ZB3_STATUS 
                    ,ZF1_DTINTA,ZF1_HRINTA,ZF1_DTCONA,ZF1_HRCONA,ZF1_DTINTA+ZF1_HRINTA INDICE1,ZZK_NOME,
                    DATEDIFF(DAY, CONVERT(DATE, ZF1_DTINTA), GetDate()) DIAS_ATENDIMENTO,
                    CASE WHEN DATEDIFF(DAY, CONVERT(DATE, ZF1_DTINTA), GetDate()) > 0 THEN 'FORA DO PRAZO' ELSE 'NO PRAZO' END STATUS_OS,
                     CASE WHEN DATEDIFF(DAY, CONVERT(DATE, ZF1_DTCONA), GetDate()) > 0 THEN 'FORA DO PRAZO' ELSE 'NO PRAZO' END STATUS_OS1`,
            INNER: `INNER JOIN SA1010 SA1 WITH(NOLOCK) ON 
                                 A1_COD=ZF1_CODCLI 
                                 AND A1_LOJA=ZF1_LOJA 
                                 AND SA1.D_E_L_E_T_=' ' 
                    INNER JOIN ZB3010 ZB3 WITH(NOLOCK) ON 
                                 ZB3.D_E_L_E_T_=' '
                                 AND ZB3_CODOS=ZF1_ID
                                 AND ZB3_SITUAC='P' 
                                 AND ZB3_STATUS IN ('000005','000018','000021')
                    LEFT JOIN ZB2010 ZB2 WITH(NOLOCK) ON 
                                 ZB2.D_E_L_E_T_=' '
                                 AND ZB2_COD=ZB3_STATUS 
                    INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON 
                                 ZZK.D_E_L_E_T_=' '
                                 AND ZZK_COD=ZF1_CODTEC `,
            WHERE: `ZF1.D_E_L_E_T_=' ' 
                    AND ZF1_TIPO='01' 
                    --AND ZF1_STATUS IN ('01','03','98') 
                    AND ZF1_CODTEC='${item.ZZK_COD}' 
                    AND ZF1_DTBIPA<='${this.ano}${this.mes}${this.dia}' 
                    AND ZF1_DTBIPA<>''
                    ORDER BY A1_NOME `,
            TABELA: "ZF1",
          };

          const itens = await axios.post(url, dados);

          itens.data.map(
            e =>
              (e.ZF1_EMISSA = `${e.ZF1_EMISSA.substr(
                6,
                2,
              )}/${e.ZF1_EMISSA.substr(4, 2)}/${e.ZF1_EMISSA.substr(0, 4)}`),
          );

          item.OS = this.separaFila(
            itens.data,
            parseInt(this.nrFilaAtendimento),
          );
        }),
      );

      this.controlOs = tecnicos.data;

      // const result = this.controlOs.map(e=> e.OS).flatMap(num => num)

      // console.log(result)

      this.paginas = this.controlOs.map(e => e.OS);

      this.banners = [];

      for (let i = 0; i < this.paginas.length; i++) {
        this.paginas[i].map(e => this.banners.push({ pagina: e }));
      }

      let contador = 1;

      this.controlOs.map(e => {
        if (e.OS.length <= 0) {
          this.banners.push({
            tecnico: e.ZZK_NOME,
            pag: `Pag. 1 de 1`,
            pagina: [{ ZZK_NOME: e.ZZK_NOME }],
          });
        }

        this.banners.map(t => {
          let totalPag = this.banners.filter(
            t => t.pagina[0].ZZK_NOME === e.ZZK_NOME,
          ).length;
          if (t.pagina[0].ZZK_NOME === e.ZZK_NOME) {
            (t.tecnico = e.ZZK_NOME),
              (t.pag = `Pag. ${contador} de ${totalPag}`);
            contador++;
          }
        });
        contador = 1;
      });

      this.intervalPag = setInterval(() => {
        this.valueBar = (this.valueBar + 0.25) % 100;
      }, "50");

      this.timerContSet = setInterval(this.contTimer, "1000");

      this.atualizaDados = false;
    },
    async buscaNrAtendimento() {
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "X6_CONTEUD",
        WHERE: `X6_VAR='MV_SXNRAT'`,
        TABELA: "SX6",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const numeroFila = await axios.post(url, dados);

      this.nrFilaAtendimento = numeroFila.data[0].X6_CONTEUD;
    },
    separaFila(arr, tamanho) {
      const newArr = [];
      let i = 0;
      while (i < arr.length) {
        newArr.push(arr.slice(i, i + tamanho));
        i += tamanho;
      }
      return newArr;
    },
    zeraProgress() {
      this.valueBar = 0;
    },
    // start() {
    //   clearTimeout(this.timer);
    //   if (this.cont > 0) {
    //     this.cont--;
    //     if (this.cont === 0) {
    //       this.contador++;
    //       clearTimeout(this.timer);
    //       this.chamaRelat();
    //     }
    //     this.timer = setTimeout(this.start, 1000);
    //   }
    // },
    contTimer() {
      if (this.min > 0 || this.seg > 0) {
        if (this.seg == 0) {
          this.seg = 59;
          this.min = this.min - 1;
        } else {
          this.seg = this.seg - 1;
        }
        if (this.min.toString().length == 1) {
          this.min = "0" + this.min;
        }
        if (this.seg.toString().length == 1) {
          this.seg = "0" + this.seg;
        }
        this.timer = this.min + ":" + this.seg;
      } else {
        this.timer = "00:00";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalAtualiza);
    clearInterval(this.intervalPag);
    clearInterval(this.timerContSet);
  },
  mounted() {
    this.buscaOS();
    this.intervalAtualiza = setInterval(this.buscaOS, "180000");
    // this.intervalAtualiza = setInterval(this.buscaOS, "30000");
  },
};
</script>

<style></style>
